import { animate } from "popmotion"

const menuButtons = document.querySelectorAll(".hamburger-menu")
const nav = document.querySelector(".nav-wrapper")

menuButtons.forEach((menuButton) => {
    menuButton.addEventListener("click", () => {
        if (nav.classList.contains("hidden")) {
            nav.classList.remove("hidden")
            animate({
                from: 0,
                to: 1,
                onUpdate: (v) => {
                    nav.style.opacity = `${v}`
                },
            })
        } else {
            animate({
                from: 1,
                to: 0,
                onUpdate: (v) => {
                    nav.style.opacity = `${v}`
                },
                onComplete: () => {
                    nav.classList.add("hidden")
                },
            })
        }
    })
})

const alwaysOpenMenus = document.querySelectorAll(".current-menu-ancestor")
const menuItems = document.querySelectorAll(".menu-item-has-children")

menuItems.forEach((menuItem) => {
    menuItem.addEventListener("mouseenter", () => {
        if (window.innerWidth <= 992) {
            return
        }

        const other = Array.from(alwaysOpenMenus).find(
            (it) => it.id !== menuItem.id
        )

        if (other) {
            const otherSubmenu = other.querySelector(".sub-menu")
            if (otherSubmenu) {
                otherSubmenu.classList.add("other-menu-is-open")
            }
        }

        const submenu = menuItem.querySelector(".sub-menu")
        if (submenu) {
            submenu.classList.add("visible")
        }
    })

    menuItem.addEventListener("mouseleave", () => {
        if (window.innerWidth <= 992) {
            return
        }

        const submenu = menuItem.querySelector(".sub-menu")
        submenu.classList.remove("visible")

        alwaysOpenMenus.forEach((other) => {
            const otherSubmenu = other.querySelector(".sub-menu")
            otherSubmenu.classList.remove("other-menu-is-open")
        })
    })
})

const submenuButtons = document.querySelectorAll(".sub-menu-toggle")
submenuButtons.forEach((submenuButton) => {
    // submenuButton.addEventListener("click", () => {
    //     const submenu = submenuButton.parentElement.querySelector(".sub-menu")

    //     submenu.style.display = "block"
    // })

    submenuButton.addEventListener("click", () => {
        if (window.innerWidth > 991) {
            return
        }

        const subMenu = submenuButton.parentElement.querySelector(".sub-menu")
        subMenu.style.opacity = "1"

        const chevron =
            submenuButton.parentElement.querySelector(".sub-menu-toggle")

        if (subMenu.getAttribute("data-is-mobile-nav-open") === "true") {
            animate({
                from: 90,
                to: 0,
                onUpdate: (value) =>
                    (chevron.style.transform = `rotate(${value}deg)`),
            })

            animate({
                from: subMenu.clientHeight,
                to: 0,
                onComplete: () => {
                    subMenu.style.display = "none"
                    subMenu.setAttribute("data-is-mobile-nav-open", false)
                    subMenu.style.height = "auto"
                },
                onUpdate: (value) => (subMenu.style.height = `${value}px`),
            })
        } else {
            animate({
                from: 0,
                to: 90,
                onUpdate: (value) =>
                    (chevron.style.transform = `rotate(${value}deg)`),
            })

            subMenu.style.display = "block"

            animate({
                from: 0,
                to: subMenu.clientHeight,
                onPlay: () => {
                    subMenu.setAttribute("data-is-mobile-nav-open", true)
                },
                onComplete: () => {
                    subMenu.style.height = "auto"
                },
                onUpdate: (value) => (subMenu.style.height = `${value}px`),
            })
        }
    })
})

const blocks = document.querySelectorAll(".review-slider-wrapper")

blocks.forEach((block) => {
    block.querySelectorAll(".review-slide").forEach((slide) => {
        const key = slide.getAttribute("id")

        const stars_amount = parseFloat(
            block.querySelector(`.ubo-stars__${key}_amount`).textContent
        )
        block.querySelector(`.ubo-stars__${key}_amount`).style.display = "none"
        ;[1, 2, 3, 4, 5].forEach((star) => {
            if (stars_amount >= star) {
                slide.querySelector(
                    `#ubo-stars__${key}_star-${star}`
                ).children[1].attributes.offset.value = "100%"
            } else if (stars_amount < star && star - stars_amount < 1) {
                console.log(`${100 * (stars_amount - (star - 1))}%`)
                slide.querySelector(
                    `#ubo-stars__${key}_star-${star}`
                ).children[1].attributes.offset.value = `${
                    100 * (stars_amount - (star - 1))
                }%`
            }
        })
    })
})

import Swiper, { Autoplay } from "swiper"

Swiper.use([Autoplay])

const blocks = document.querySelectorAll(".review-slider")

blocks.forEach((block) => {
    const swiper = new Swiper(block, {
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
    })
    const slides = block.querySelectorAll(".swiper-slide")

    slides.forEach((slide) => {
        const prev = slide.querySelector(".slide-prev")
        const next = slide.querySelector(".slide-next")

        prev.addEventListener("click", () => {
            swiper.slidePrev()
        })

        next.addEventListener("click", () => {
            swiper.slideNext()
        })

        const description = slide.querySelector(".review-description")
        jQuery(description).readmore({
            speed: 75,
            collapsedHeight: 100,
            moreLink: '<a class="mb-4 -mt-4 block" href="#">Lees meer</a>',
            lessLink: '<a class="mb-4 -mt-4 block" href="#">Lees minder</a>',
        })
    })
})

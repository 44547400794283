import Swiper, { Navigation, Thumbs } from "swiper"

Swiper.use([Navigation, Thumbs])

const page = document.querySelector("body.single-occasions")

if (page) {
    const swiper = new Swiper(".other-images", {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: false,
        watchSlidesProgress: true,
    })
    new Swiper(".featured-image", {
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    })
}

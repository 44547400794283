import dayjs from "dayjs"
import { findLast, isEqual } from "lodash"

export const containerStyle = {
    width: "100%"
}

export const center = {
    lat: 52.2220994,
    lng: 5.3098101,
}
export const styles = []
export const radiuses = [
    {
        value: 0,
        text: "Afstand",
    },
    {
        value: 10,
        text: "10km",
    },
    {
        value: 20,
        text: "20km",
    },
    {
        value: 30,
        text: "30km",
    },
    {
        value: 40,
        text: "40km",
    },
    {
        value: 50,
        text: "50km",
    },
    {
        value: 100,
        text: "100km",
    },
]
export function convertTime(schedule) {
    const grossierSchedule = schedule

    // convert all times into a moment object
    const week = Object.keys(grossierSchedule).map((key) => {
        const day = grossierSchedule[key]
        day.value = day.value.map((time) => {
            return {
                start: dayjs(time.start).format("HH:mm"),
                end: dayjs(time.end).format("HH:mm"),
            }
        })

        return day
    })

    // Mark all duplicates
    week.forEach((day, index, self) => {
        const duplicate = self.findIndex((t) => isEqual(t.value, day.value))

        if (index !== duplicate) {
            // check if theres day inbetween which doesnt have the same time
            if (
                index - 1 === duplicate ||
                (week[index - 1] && week[index - 1].inherits !== undefined)
            ) {
                week[index].inherits = duplicate
            }
        }
    })

    const daysWithInherits = week
        .filter((i) => i.inherits !== undefined)
        .filter((day, index, self) => {
            const duplicate = self.findIndex((t) => isEqual(t.value, day.value))
            return index === duplicate
        })

    daysWithInherits.forEach((uniqueDay) => {
        const isLast = findLast(
            week,
            (item) => item.inherits === uniqueDay.inherits
        )
        week[isLast.inherits].name = `${week[isLast.inherits].name} t/m ${
            isLast.name
        }`
    })

    return week.filter((t) => t.inherits === undefined)
}

export function sortGrossier(a, b) {
    let sorting = 0;

    if (a.prio < b.prio) {
        sorting += -1;
    }

    if (a.prio > b.prio) {
        sorting += 1;
    }


    if (a.distance !== undefined && b.distance !== undefined) {
        if (a.distance < b.distance) {
            sorting += -1
        }

        if (a.distance > b.distance) {
            sorting += 1
        }
    } else {
        sorting += a.title.localeCompare(b.title)
    }


    return sorting;

    // if (a.distance !== undefined && b.distance !== undefined) {
    //     if (a.distance < b.distance) {
    //         return -1
    //     }

    //     if (a.distance > b.distance) {
    //         return 1
    //     }

    //     return 0
    // }

    // const nameA = a.prio
    // const nameB = b.prio
    // if (nameA < nameB) {
    //     return -1 - a.title.localeCompare(b.title)
    // }
    // if (nameA > nameB) {
    //     return 1 - a.title.localeCompare(b.title)
    // }

    // // names must be equal
    // return a.title.localeCompare(b.title)
}

import Swiper, { Autoplay } from "swiper"

const blocks = document.querySelectorAll(".banner-slider")

blocks.forEach((block) => {
    const swiper = new Swiper(block, {
        modules: [Autoplay],
        autoplay: {
            delay: 10000,
        },
    })
    const slides = block.querySelectorAll(".swiper-slide")

    slides.forEach((slide) => {
        const prev = slide.querySelector(".slide-prev")
        const next = slide.querySelector(".slide-next")

        prev.addEventListener("click", () => {
            swiper.slidePrev()
        })

        next.addEventListener("click", () => {
            swiper.slideNext()
        })
    })
})

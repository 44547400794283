import { animate } from "popmotion"

const blocks = document.querySelectorAll(".extension-block")

blocks.forEach((block) => {
    const mouseEnter = () => {
        const description = block.querySelector(".block-description")
        description.style.display = "block"
        
        animate({
            from: 0,
            to: description.scrollHeight,
            onUpdate: (v) => {
                description.style.height = `${v}px`
            }
        })
    }

    const mouseLeave = () => {
        const description = block.querySelector(".block-description")

        animate({
            from: description.scrollHeight,
            to: 0,
            onUpdate: (v) => {
                description.style.height = `${v}px`
            }
        })
    }

    block.addEventListener("mouseenter", mouseEnter)
    block.addEventListener("mouseleave", mouseLeave)
})

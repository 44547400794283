const blocks = document.querySelectorAll(".review-total")

blocks.forEach((block) => {
    const key = block.getAttribute("id")

    const stars_amount = parseFloat(
        block.querySelector(`.ubo-stars__${key}_amount`).textContent
    )
    block.querySelector(`.ubo-stars__${key}_amount`).style.display = "none"

    ;[1, 2, 3, 4, 5].forEach((star) => {
        if (stars_amount >= star) {
            block.querySelector(
                `#ubo-stars__${key}_star-${star}`
            ).children[1].attributes.offset.value = "100%"
        } else if (stars_amount < star && star - stars_amount < 1) {
            block.querySelector(
                `#ubo-stars__${key}_star-${star}`
            ).children[1].attributes.offset.value = `${
                100 * (stars_amount - (star - 1))
            }%`
        }
    })
})

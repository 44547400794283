import { Loader } from "google-maps"
import { styles } from "../location/util"

const mapContainer = document.getElementById("marked-map")
if (mapContainer) {
    const loader = new Loader("AIzaSyBzq4Ixboz3rLpxFuYL3Dia1OtbPtruQg0")

    loader.load().then(async (google) => {
        const lat = parseFloat(mapContainer.getAttribute("data-lat"))
        const lng = parseFloat(mapContainer.getAttribute("data-lng"))
        const title = mapContainer.getAttribute("data-title")
        const address = JSON.parse(mapContainer.getAttribute("data-address"))

        const map = new google.maps.Map(mapContainer, {
            center: { lat: lat, lng: lng },
            zoom: 15,
            disableDefaultUI: true,
            styles: [
                {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                },
            ],
        })

        const marker = new google.maps.Marker({
            position: {
                lat: lat,
                lng: lng,
            },
            title: title,
            map: map,
            icon: {
                // size: new google.maps.Size(55, 62),
                url: `${window.ubo.asset}/marker-new.png`,
                labelOrigin: new google.maps.Point(16.5, 45),
            },
            label: {
                text: title,
                color: "#327FEF",
                fontSize: "18px",
                fontWeight: "500",
            },
        })

        const content = `
                <div class="infoWindow">
                    <h5 class="mb-1">${title}</h5>
                    <p class="mb-0">${address.street}</p>
                    <p class="mb-0">${address.zipcode}</p>
                    <p class="mb-2">${address.city}</p>
                    <a
                        href="https://google.com/maps/dir/?api=1&travelmode=driving&destination=${address.street},${address.zipcode} ${address.city}"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="mb-4 font-bold"
                    >
                        > Naar routebeschrijving
                    </a>

                </div>
            `

        const infoWindow = new google.maps.InfoWindow({
            content: content,
        })

        marker.addListener("click", function () {
            infoWindow.open(map, marker)
        })
    })
}

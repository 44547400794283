import Swiper, { Autoplay, Navigation } from "swiper"

Swiper.use([Navigation, Autoplay])

const blocks = document.querySelectorAll(".highlighted-occasions")

blocks.forEach((block) => {
    new Swiper(block.querySelector(".highlighted-occasions-swiper"), {
        spaceBetween: 20,
        slidesPerView: 2,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },

        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
    })

    const occasions = block.querySelectorAll(".occasion")

    occasions.forEach((occasion) => {
        const swiperInstance = new Swiper(
            occasion.querySelector(".attributes-slider"),
            {
                spaceBetween: 10,
                slidesPerView: "auto",
                freeMode: true,
                autoplay: {
                    delay: 1000,
                    disableOnInteraction: false,
                },
                onSwiper: function (instance) {
                    console.log(instance)
                },
            }
        )

        swiperInstance.autoplay.stop()

        occasion.addEventListener("mouseenter", function () {
            swiperInstance.autoplay.start()
        })

        occasion.addEventListener("mouseleave", function () {
            swiperInstance.autoplay.stop()
        })
    })
})

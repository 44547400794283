import Swiper, { Autoplay } from "swiper"

Swiper.use([Autoplay])

const blocks = document.querySelectorAll(".image-slider-container")

blocks.forEach((block) => {
    const slider = block.querySelector(".image-slider")
    if (!slider) return

    const swiper = new Swiper(slider, {
        loop: false,
        slidesPerView: 1,
        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 5,
            },
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
    })

    const prev = block.querySelector(".slide-prev")
    const next = block.querySelector(".slide-next")

    if (prev) {
        prev.addEventListener("click", () => {
            swiper.slidePrev()
        })
    }

    if (next) {
        next.addEventListener("click", () => {
            swiper.slideNext()
        })
    }
})

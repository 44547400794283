import { Loader } from "google-maps"

const mapContainer = document.getElementById("location-map")
if (mapContainer) {
    const loader = new Loader("AIzaSyBzq4Ixboz3rLpxFuYL3Dia1OtbPtruQg0")

    loader.load().then(async (google) => {
        let defPos = {
            center: { lat: 52.1598073, lng: 1.5 },
            zoom: 7,
            disableDefaultUI: true,
            styles: [
                {
                    featureType: "administrative.country",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                },
            ],
        }

        if (window.innerWidth <= 768) {
            defPos.center = { lat: 52.1598073, lng: 2.5 }
            defPos.zoom = 6.8
        }

        if (window.innerWidth <= 640) {
            defPos.center = { lat: 52.5598073, lng: 4.4 }
            defPos.zoom = 6.8
        }

        const map = new google.maps.Map(mapContainer, defPos)

        const response = await fetch(
            window.ubo.rest_url + "sites/v1/grossiers/locations"
        )

        if (!response.ok) {
            return false
        }

        const results = await response.json()

        for (const result of results) {
            new google.maps.Marker({
                position: {
                    lat: result.latitude,
                    lng: result.longitude,
                },
                map: map,
                icon: {
                    url: `${window.ubo.asset}/marker.png`,
                },
            })
        }
    })
}
